<template>
  <div class="index" v-if="!isMobile">
    <!-- <img src="@/assets/bg.png" class="bg" />
    <img src="@/assets/tit.png" class="tit" /> -->
    <img :src="bg" class="bg" />
    <img :src="titImg" class="tit" />
    <div class="site flex align">
      <img src="@/assets/bg5.png" class="bg5" />
      <img src="@/assets/icon5.png" class="icon" />
      <div class="site-txt">当前点位：{{ site }}</div>
    </div>
    <div class="nav-list">
      <div class="item flex align" @click="toSign">
        <img src="@/assets/bg2.png" class="bg2" />
        <img src="@/assets/icon1.png" class="icon" />
        <div class="item-info">
          <div>签到打卡</div>
          <div>人脸识别快速打卡</div>
        </div>
      </div>
      <div class="item flex align">
        <img src="@/assets/bg2.png" class="bg2" />
        <!-- <img src="@/assets/icon2.png" class="icon" /> -->
        <div class="icon2">
          <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
        <div class="item-info">
          <div>添加人员</div>
          <div>扫码添加残疾人及信息</div>
        </div>
      </div>
    </div>
    <van-overlay :show="showLoad">
      <div class="wrapper">
        <van-loading
          type="spinner"
          vertical
          size="80"
          text-size="40"
          color="#E26A26"
          text-color="#E26A26"
          >请稍后...</van-loading
        >
      </div>
    </van-overlay>
    <!-- <button @click="getMap()">定位</button> -->
    <div id="map-container"></div>
  </div>
</template>
<script>
import {
  siteDetail,
  positionDetail,
  clockinCheck,
  getPeopleClockinData,
} from "@/api/apiData";
// import { locations } from ".@/until/location";
// import AMapLoader from "@amap/amap-jsapi-loader";
import { loadBaiDuMap } from "@/until/map.js";
import { Icon, Toast, Dialog, Overlay, Loading } from "vant";
import QRCode from "qrcodejs2";
export default {
  components: {
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  data() {
    return {
      id: "",
      bg: "",
      url: "https://ayxclrecord.nccloudmedia.com/#/login",
      site: "",
      titImg: "",
      status: 1,
      showLoad: false,
      city: "正在定位",
      distance_limit: 0,
      isMobile: false,
    };
  },
  mounted() {
    // 获取站点信息
    let id = this.$route.query.id;
    this.getPath();
    this.creatQrCode();
    if (id) {
      localStorage.setItem("site_id", id);
      siteDetail({ site_id: id }).then((res) => {
        this.site = res.data.name;
        this.status = res.data.status;
      });
    }
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 获取打卡限制配置
    getClockinData() {
      getPeopleClockinData().then((res) => {
        this.distance_limit = res.data.distance_limit;
      });
    },
    getCity() {
      let that = this;
      var Datas = sessionStorage.getItem("obj");
      console.log(Datas);
      if (Datas) {
        let points = JSON.parse(Datas);
        console.log(points);
        that.toNewSign(points.lng, points.lat);
      } else {
        loadBaiDuMap().then((BMapGL) => {
          return new Promise(function (resolve, reject) {
            // 创建地图实例
            let geolocation = new BMapGL.Geolocation(); // 创建百度地理位置实例，代替 navigator.geolocation
            geolocation.getCurrentPosition(function (e) {
              console.log(e, "e");

              if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                // 百度 geolocation 的经纬度属性不同，此处是 point.lat 而不是 coords.latitude
                let point = new BMapGL.Point(e.point.lng, e.point.lat);
                console.log(point, "point");
                var obj = JSON.stringify(point);
                sessionStorage.setItem("obj", obj);
                that.toNewSign(point.lng, point.lat);
                let gc = new BMapGL.Geocoder();
                gc.getLocation(point, function (rs) {
                  resolve(rs);
                });
              } else {
                console.log(this.getStatus());
                reject(this.getStatus());
              }
            });
          });
        });
      }
    },
    toNewSign(lng, lat) {
      let that = this;
      clockinCheck({ longitude: lng, latitude: lat }).then((res) => {
        that.showLoad = false;
        if (res.code == 1) {
          if (res.data.status == 1) {
            that.$router.push("/sign");
          } else {
            Dialog.alert({
              width: 800,
              className: "newClass",
              title: "提示",
              message: "距离打卡点" + res.data.distance + "米，不在打卡范围内",
            }).then(() => {});
          }
        } else {
          Dialog.alert({
            width: 800,
            className: "newClass",
            title: "提示",
            message: res.msg,
          }).then(() => {});
        }
      });
    },
    initMap(id) {
      let mapObj = new AMap.Map(id, {});
      let geolocation;
      mapObj.plugin(["AMap.Geolocation"], function () {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //  是否使用高精度定位，默认:true
          timeout: 10000, //  超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //  显示定位按钮，默认：true
          buttonPosition: "LB", // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), //  定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, //  定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //  定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //  定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition();
      });
      return geolocation;
    },
    getLongitudeLatitude() {
      let that = this;
      //如果该对象存在，那么地理位置服务可用。
      if ("geolocation" in navigator) {
        /* 地理位置服务可用 */
        var options = {
          enableHighAccuracy: true, //布尔值，表示系统是否使用最高精度来表示结果，注意，这会导致较慢的响应时间或者增加电量消耗（比如对于支持gps的移动设备来说）。如果值为false ，设备会通过更快响应以及/或者使用更少的电量等方法来尽可能的节约资源。默认值fasle
          timeout: 5000, //它表明的是设备必须在多长时间（单位毫秒）内返回一个位置。默认直到获取到位置才会返回值。
          maximumAge: 0, //表明可以返回多长时间（即最长年龄，单位毫秒）内的可获取的缓存位置。如果设置为 0, 说明设备不能使用一个缓存位置，而且必须去获取一个真实的当前位置。默认0
        };
        function success(position) {
          var lat = position.coords.latitude; //当前位置的纬度
          var lng = position.coords.longitude; //当前位置精度
          that.toNewSign(lng, lat);
        }
        function error(err) {
          var errorType = [
            "您拒绝共享位置信息",
            "获取不到位置信息",
            "获取位置信息超时",
          ];
          Toast(errorType[err.code - 1]);
          console.log(errorType[err.code - 1]);
          that.showLoad = false;
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        /* 地理位置服务不可用 */
        Toast("无法获取您的位置，请检查定位是否开启或刷新重试");
        console.log("无法获取您的位置，请检查定位是否开启或刷新重试");
        that.showLoad = false;
      }
    },
    // 显示定位地图以及获取当前经纬度地址
    getMap() {
      let that = this;
      AMapLoader.load({
        key: "73e5067aebb962c80e3a5dac310f3fef", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          AMap.plugin("AMap.Geolocation", function () {
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              timeout: 10000, //超过10秒后停止定位，默认：5s
              buttonPosition: "RB", //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
            });
            // map.addControl(geolocation);
            geolocation.getCurrentPosition(function (status, result) {
              if (status == "complete") {
                that.onComplete(result);
              } else {
                that.showLoad = false;
                that.onError(result);
              }
            });
          });
        })
        .catch((e) => {
          //console.log(e);
        });
    },
    // 定位成功
    onComplete(data) {
      console.log(data);
      let that = this;
      let getposition = data.position;
      that.toNewSign(getposition.lng, getposition.lat);
    },
    onError(data) {
      Toast("定位失败：" + data.message);
      console.log(data.message);
    },
    // 获取首页图片
    getPath() {
      positionDetail({ position_id: 1 }).then((res) => {
        if (res.code == 1) {
          this.titImg = res.data.path;
        } else {
          Toast.fail(res.msg);
        }
      });
      positionDetail({ position_id: 2 }).then((res) => {
        if (res.code == 1) {
          this.bg = res.data.path;
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    creatQrCode() {
      let url = this.url;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    toSign() {
      if (this.status == 0) {
        Dialog.alert({
          width: 800,
          className: "newClass",
          title: "提示",
          message: "本站点的签到打卡关闭中",
        }).then(() => {});
        return false;
      }
      this.$router.push("/sign");
      // this.showLoad = true;
      // getPeopleClockinData().then((res) => {
      //   let distance_limit = res.data.distance_limit;
      //   if (distance_limit == 1) {
      //     this.getCity();
      //   } else {
      //     this.showLoad = false;
      //     this.$router.push("/sign");
      //   }
      // });
      // this.getMap();
      // this.getLongitudeLatitude()
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .tit {
    width: 911px;
    display: block;
    margin: 140px auto 61px;
  }
  .nav-list {
    width: 900px;
    margin: 0 auto;
  }
  .item {
    // background: #e9493413;
    padding: 45px 48px;
    // border: solid 1px #E94A34;
    border-radius: 8px;
    margin-bottom: 88px;
    position: relative;
    .bg2 {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .icon {
      width: 250px;
      margin-right: 48px;
    }
    .icon2 {
      width: 230px;
      height: 230px;
      margin-right: 48px;
      border: solid 4px #e36d28;
      border-radius: 20px;
      background: #fceee1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item-info {
      line-height: 2;
      div:nth-child(1) {
        font-size: 72px;
      }
      div:nth-child(2) {
        font-size: 48px;
        color: #666666;
      }
    }
  }
  .site {
    position: relative;
    width: 100%;
    color: #ff9700;
    font-size: 40px;
    height: 97px;
    padding: 20px 0;
    // font-weight: bold;
    // font-weight: bold;
    // line-height: 97px;
    justify-content: center;
    margin: 61px auto 90px;
    .icon {
      width: 30px;
      margin-right: 20px;
    }
    .bg5 {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .site-txt {
      width: 80%;
    }
  }
  // .site {
  //   position: relative;
  //   width: 100%;
  //   color: #ff9700;
  //   font-size: 36px;
  //   height: 97px;
  //   line-height: 97px;
  //   justify-content: center;
  //   margin: 61px 0 97px;
  //   .icon {
  //     width: 28px;
  //     margin-right: 20px;
  //   }
  //   .bg5 {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}
</style>